<template>
  <div id="Manage">
    <!-- 基础设置 景点管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加景点元素</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchScenic(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage"
                     @changeSwitch="changeSwitch">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>删除</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getScenicList, addScenicList, putStatusScenicListData, addScenicListDetail
}
  from 'api/basis.js'
import {
  getAreaFirst,
  getAreaCode
}
  from 'api/basisDate.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      areaName: [],
      areaID: [],
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增景点',
        editName: '编辑景点',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        name: '',
        province_name: '',
        city_name: '',
        area_name: '',
        province_id: '',
        city_id: '',
        area_id: '',
        town_id: 0,
        address: '',
        type_tags: '',
        images: [],
        open_time_info: '',
        description: '',
        // area_id: 0,
        // city_id: 0,
        // province_id: 0,
        area: ""
      },
      openFormLabel: [
        {
          model: 'name',
          label: '景点名称',
        },
        {
          model: 'province_name',
          // label: '省份',
          hidden: true
        },
        {
          model: 'city_name',
          // label: '城市'
          hidden: true
        },
        {
          model: 'area_name',
          // label: '县/区级'
          hidden: true
        },
        {
          model: 'area',
          area: {
            area1: 'province_id',
            area2: 'city_id',
            area3: 'area_id',
            area4: 'town_id'
          },
          label: '景点地址',
          type: 'cascaderArea',
          width: '100%',
          // required: true
        },
        {
          model: 'address',
          label: '详细地址'
        },
        {
          model: 'type_tags',
          label: '标记',
        },
        {
          model: 'images',
          label: '景点图',
          type: 'uploadList',
          maxSize: 500,
          imgHeight: '1080',
          imgWidth: '808',
        },
        {
          model: 'open_time_info',
          label: '开放时间',
          type: 'selectTime'
        },
        {
          model: 'description',
          label: '描述',
        },
      ],

      // form表单 搜索数据
      searchForm: {
        scenicName: '',
        scenicState: '',
        province_id: '',
        city_id: ''
      },
      searchformLabel: [
        {
          model: 'scenicName',
          label: '景点名称',
          width: '300px'
        },
        {
          model: 'province_id',
          area1: 'province_id',
          label: '地址',
          type: 'provinceArea',
          width: '250px',
          placeholder: '省级'
        },
        {
          model: 'city_id',
          area2: 'city_id',
          type: 'cityArea',
          width: '90px',
          placeholder: '市级'
        },
        {
          model: 'scenicState',
          label: '状态',
          type: 'select',
          width: '250px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '启用' },
            { value: 0, label: '禁用' },
          ],
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'ID',
          label: '景点ID',
        },
        {
          prop: 'name',
          label: '景点名称'
        },
        {
          prop: 'images',
          label: '景点图'
        },
        {
          prop: 'type_tags',
          label: '标记'
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'stateS',
        },
        {
          prop: 'province_name',
          label: '省份名称'
        },
        {
          prop: 'city_name',
          label: '城市'
        },

        {
          prop: 'Cdate',
          label: '更新时间'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


      // 景点-全部-数据
      scenicAlldataObj: {},

      searchData: '',//存放搜索的数据，分页时使用
      // id最大值
      MaxId: ''
    }
  },

  mounted () {
    this.getScenicList();

  },
  beforeUpdate () {
  },
  watch: {
    scenicAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.scenicAlldataObj.list,
        {
          name: 'name',
          id: "ID",
          type_tags: 'type_tags',
          province_id: 'province_name',
          city_id: 'city_name',
          area_name: 'area_name',
          description: 'description',
          update_time: 'Cdate',
        },
      )
      console.log(this.tableData)
      this.tableData.forEach(item => {
        var imagesArr = Object.values(item.images[0])
        imagesArr = imagesArr.join("")
        item.images[0] = imagesArr
      })
      this.handleID()
    }
  },

  methods: {
    // 处理省份ID
    handleID () {
      let arr2 = [];
      getAreaFirst().then(res => {
        arr2 = res.data.data
        arr2.forEach((item, index) => {
          this.tableData.forEach(item2 => {
            if (item.id == item2.province_name) {
              item2.province_name = item.name
              getAreaCode({ code: item.code }).then(res => {
                res.data.data.forEach(city => {
                  if (city.id == item2.city_name) {
                    item2.city_name = city.name
                  }
                })
              })
            }
          })
        })
      })
    },
    //获得下列分类页的页数
    getPage () {
      // this.getScenicList()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getScenicList(this.searchData)
      } else {
        this.getScenicList()
      }
    },
    // 获取--景点元素列表数据
    getScenicList (data) {
      if (data) {
        getScenicList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.scenicAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getScenicList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.scenicAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }



    },


    //添加数据   --添加-编辑 景点
    addScenicList (met, data) {
      return new Promise(resolve => {
        addScenicList(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },

    //状态变更
    putStatusScenicListData (met, data) {
      putStatusScenicListData(met, data).then(res => {
        console.log(res)
        const DATA = res.data
        if (DATA.code == 200) {
          this.$message({
            message: DATA.message,
            type: 'success'
          })
        } else {
          this.$message({
            message: DATA.message,
            type: 'error'
          })
        }
      })
    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxID(this.tableData, 'ID')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true

      console.log(this.scenicAlldataObj.list)

    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      this.areaName = this.$refs.form.areaName
      this.areaID = this.$refs.form.areaID
      console.log(this.areaName, this.areaID)

      if (type == 'add') {
        const DATA = {
          name: data.name,
          province_name: this.areaName[0],
          city_name: this.areaName[1],
          area_name: this.areaName[2],
          province_id: this.areaID[0],
          city_id: this.areaID[1],
          area_id: this.areaID[2],
          town_id: 0,
          address: data.address,
          type_tags: data.type_tags,
          images: data.images,
          open_time_info: data.open_time_info,
          description: data.description,
        }
        // console.log(DATA)
        this.addScenicList('POST', DATA)
        this.getScenicList()

      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          province_name: data.province_name,
          city_name: data.city_name,
          area_name: data.area_name,
          province_id: data.province_id,
          city_id: data.city_id,
          area_id: data.area_id,
          town_id: 0,
          address: data.address,
          type_tags: data.type_tags,
          images: data.images,
          open_time_info: data.open_time_info,
          description: data.description,
        }
        this.addScenicList('PUT', DATA)
        this.getScenicList()
      }
      this.updataTableData()
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm;
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 编辑操作
    async clickEditData (idx, row) {
      const DATA = {
        id: row.ID
      }
      console.log(DATA)
      let result = await this.addScenicListDetail(DATA)
      let data = result.data.data
      console.log(result)
      console.log(data)
      getAreaFirst().then(res => {
        res.data.data.forEach(item => {
          this.openForm =
          {
            id: data.id,
            name: data.name,
            address: data.address,
            province_name: data.province_name,
            city_name: data.city_name,
            area_name: data.area_name,
            province_id: data.province_id,
            city_id: data.city_name,
            area_id: data.area_id,
            town_id: 0,
            area: [data.province_id,
            data.city_id,
            data.area_id,
            ],
            address: data.address,
            type_tags: data.type_tags,
            images: data.images,
            open_time_info: data.open_time_info,
            description: data.type_tags,
            status: data.state
          }
        })
      })


      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    //详情景点元素数据
    addScenicListDetail (data) {
      return new Promise(resolve => {
        addScenicListDetail(data).then((res) => {
          resolve(res)
        })
      })
    },
    // 删除操作
    clickDeleteData (idx, row) {
      const DATA = {
        id: row.ID
      }
      console.log(row.id)
      this.addScenicList('DELETE', DATA)
      this.updataTableData()
    },
    //刷新表格
    updataTableData () {
      this.getScenicList()
    },
    // 表格开关操作
    changeSwitch (row) {
      // id		品牌ID
      // status	int	状态 1启用 0禁用
      console.log(row)
      const DATA = {
        id: row.ID,
        status: row.status
      }
      console.log(DATA)
      this.putStatusScenicListData('PUT', DATA)
    },
    //搜索景点元素
    searchScenic (searchForm) {
      console.log(searchForm)
      const data = {
        search: searchForm.scenicName,
        province_id: searchForm.province_id,
        city_id: searchForm.city_id,
        status: searchForm.scenicState
      }
      this.searchData = data
      this.getScenicList(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.scenicName = '',
        searchForm.province_id = '',
        searchForm.city_id = '',
        searchForm.scenicState = ''
      this.searchData = ''
      this.getScenicList()
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  width: 100%;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    width: 85%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-main {
    width: 100%;
  }
}
</style>
